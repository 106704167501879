function initNewsletterForm() {
   updateForm($('#newsletter form'));
   validateFormInput($('#newsletter input[name="checkout"]'),
                     $('#newsletter input, #newsletter select'));
}


$(document).ready(function() {
   initNewsletterForm();

   // validate input and disabel/enable checkout button
   $('#newsletter').on('change keyup change paste', 'input, select', function() {
     validateFormInput($('#newsletter input[name="checkout"]'),
                       $('#newsletter input, #newsletter select'));
   });

   // submit form
   $('#newsletter').on('submit', 'form', function(ev) {
     ev.preventDefault();
     trackEvent('newsletter-action', 'checkout');
     var form = $(this);

     $('#newsletter').addClass('loading');

     $.post(form.attr("action"), form.serialize())
       .done(function(data) {
         if (typeof data != 'string') {
           window.location = data['redirect'];
         } else {
           $('#newsletter .cart__content').html(data);
           initNewsletterForm();
           $('#newsletter').removeClass('loading');
         }
       })
       .fail(function() {
          $('#newsletter').removeClass('loading');
          console.log("error");
       })
  });
});
